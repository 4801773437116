import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

export default class Footer extends Component {
  render() {

    const year = new Date().getFullYear()
    console.log(year)
    return (
      <Navbar
        className="justify-content-center"
        expand="lg"
        bg="iyiola"
        variant="dark"
        sticky="bottom"
      >
        <Nav bg="iyiola" className="justify-content-center" activeKey="/">
          <Nav.Item>
            <Nav.Link href="/">
              Copyright © {year} Second Coming Nigeria Ltd. All rights reserved.
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
    );
  }
}
