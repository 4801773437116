import React from "react";
import Map from "../components/Map";
import { Row, Col, Button } from "react-bootstrap";
import desktopImage from "../../src/bghead.svg";
import imageUrl2 from "../../src/service.svg";
import instagram from "../img/instagram.svg";
import linkedin from "../img/linkedin.svg";
import facebook from "../img/facebook.svg";

import bgbelow from "../../src/bgbelow.svg";
import bgbelowmob from "../../src/bgbelow.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faFillDrip,
  faWrench,
  faDiagnoses,
  faGasPump,
  faMoneyCheckAlt,
  faUtensils,
  faNetworkWired,
  faChalkboardTeacher,
  faInstagram,
} from "@fortawesome/free-solid-svg-icons";

import logobelow from "../../src/logo.png";

import { Link, animateScroll as scroll } from "react-scroll";
import Outlet from "./Outlet";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

function Home() {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  const width = window.innerWidth;

  const posts = [
    {
      id: 1,
      address:
        "Plot 6 CMD Road, opposite Esporta Hotel & Suite Magodo Phase 2 extension, Lagos",
      phoneNumber: "08054899632,08075222774",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
    },

    {
      id: 2,
      address: `Lekki - Epe Expy, Opposite Crown Estate, Eti-Osa 106104, Abijo, Lagos, Eti- osa, Lagos`,
      phoneNumber: "08054999959, 08075222774",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
    },
    {
      id: 3,
      address:
        "Plot 20 Amechi Road Awkunanaw Enugu south, Enugu South, Enugu State",
      phoneNumber: "08054898702, 08075222774 ",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
      extraDays: "Sundays",
      extraTimes: "1:30pm - 5pm",
    },
    {
      id: 4,
      address:
        "10 Enugu Road beside Gincov Filling station, Agbani, Enugu State NG",
      phoneNumber: "08054898796, 08075222774 ",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
      extraDays: "Sundays",
      extraTimes: "1:30pm - 5pm",
    },
    {
      id: 5,
      address:
        "Ahani Achi Road Oji - River, Enugu State, Enugu, Enugu State 401147, NG",
      phoneNumber: "08054899583, 08075222774 ",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
      extraDays: "Sundays",
      extraTimes: "1:30pm - 5pm",
    },
    {
      id: 6,
      address:
        "Plot D18 Suncity Layout opposite NNPC Filling station along Nike Lake resort Road, Nkwo Nike, Enugu State NG",
      phoneNumber: "08054999966, 08075222774 ",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
      extraDays: "Sundays",
      extraTimes: "1:30pm - 5pm",
    },
    {
      id: 7,
      address: "Plot 29,Cadastral zone 09- 07 Karu District, Abuja",
      phoneNumber: "08054996112, 08075222774 ",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
      extraDays: "Sundays",
      extraTimes: "1:30pm - 5pm",
    },
    {
      id: 8,
      address:
        "Rukpokwu/Igwuruta Road, beside Greater Evangelism World Crusade Church. Port Harcourt.",
      phoneNumber: "08054996098, 08075222774 ",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
      extraDays: "Sundays",
      extraTimes: "1:30pm - 5pm",
    },
    {
      id: 9,
      address:
        "Nnagharigwe Alaoji along Ikot Ekpene Road, Ogbor Hill, Aba, Obingwa L.G.A Abia State.",
      phoneNumber: "08054999964, 08075222774 ",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
      extraDays: "Sundays",
      extraTimes: "1:30pm - 5pm",
    },
    {
      id: 10,
      address:
        "Okporoama Umudinkwa avodim Ubakala, Umuahia South Local Govt Area, Abia State NG",
      phoneNumber: "08054999973, 08075222774 ",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
      extraDays: "Sundays",
      extraTimes: "1:30pm - 5pm",
    },
    {
      id: 11,
      address:
        "Lodu, Lodu Ndume, Ibeku village by Ahiaeke Market Umuahia North L.G.A, Abia State, Abia State 441119, NG",
      phoneNumber: "08054899161, 08075222774 ",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
      extraDays: "Sundays",
      extraTimes: "1:30pm - 5pm",
    },
    {
      id: 12,
      address:
        "Mission Hill, Express Road Opposite ADP, Mgbaja ossah Ibeku Umuahia North L.G.A, Abia State 440102, NG",
      phoneNumber: "08054898835, 08075222774 ",
      weekDays: "Monday-Saturday (Including Public holidays)",
      weekTimes: "8am-6pm",
      extraDays: "Sundays",
      extraTimes: "1:30pm - 5pm",
    },
  ];

  const content = posts.map((post) => (
    <Outlet
      extraDays={post.extraDays}
      extraTimes={post.extraTimes}
      address={post.address}
      phoneNumber={post.phoneNumber}
      weekDays={post.weekDays}
      weekTimes={post.weekTimes}
    />
  ));

  let arr = [...Array(18).keys()];

  const strUrl = (img) => require(`../img/${img}-min.jpeg`);

  if (width <= 650) {
    var imageUrl = desktopImage;

    var headFont = 33;
    var subHeadFont = 19;
    // var paddingLeft = 19;
    var paddingTop = 50;
    var padding = 30;
    var marginTop = "10px";
    var font = 18;
    var bullets = false;
    var color = "black";
    var bgb = bgbelowmob;
    vh = "100vh";
  } else if (width >= 768 <= 1024) {
    var imageUrl = desktopImage;
    var headFont = 50;
    var subHeadFont = 30;
    // var paddingLeft = 300;
    // var paddingTop = 200;
    var padding = 50;
    var marginTop = 80;
    var font = 18;
    var bullets = true;
    var color = "black";
    // var vh = "150vh";
    var bgb = bgbelow;
  } else if (width >= 1025) {
    var vh = "110vh";
    var bgb = bgbelow;
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          minHeight: "100vh",
          padding: "50px",
        }}
      >
        <Row
          style={{
            padding: 10,
            marginTop: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="textAboveBgLight" style={{ marginTop: "30px" }}>
            <p
              className="textAboveBgMed"
              style={{
                color: "white",
                fontSize: headFont,
                textAlign: "center",
              }}
            >
              Second Coming Nigeria Limited
            </p>
            <p style={{ fontSize: subHeadFont, textAlign: "center" }}>
              The People's Gas Company <strong>|</strong> Welcome to Our
              Official Website&nbsp;
            </p>
          </div>
        </Row>
        <Row
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            activeClass="active"
            to="section1"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <div
              className="home-button"
              style={{
                marginTop: marginTop,
              }}
            >
              Know More About Us &nbsp;
            </div>
          </Link>
        </Row>
      </div>
      <div
        style={{
          color: "white",
          backgroundColor: "#000",
          textAlign: "center",
          paddingTop: paddingTop,
          padding: "20px",
        }}
        id="section1"
      >
        <p
          className="textAboveBgMed"
          style={{ fontSize: headFont, paddingTop: "20px", color: "#fff" }}
        >
          About Us
        </p>
        <p
          style={{
            color: "#fff",
            maxWidth: "800px",
            margin: "0 auto",
            padding: padding,
            fontSize: font,
            textAlign: "left",
            textJustify: "inter-character",
          }}
        >
          Second Coming Nigeria Limited is a major player in Nigeria LPG market
          with investment in LPG bulk storage and retailing through active
          participation in LPG distribution channels to ensure the products get
          to the consumers in high quality and safety.
          <br />
          <br />
          The company has been in existence for over 20 years and has uniquely
          positioned herself in the heart of its customers as a reliable outlet
          for high quality cooking gas.
          <br />
          <br />
          Second Coming Nigeria Limited has kept high standards in reliability,
          quality and service and demonstrated its commitment towards quality,
          environmental, safety systems and customer satisfaction which has
          ensured that Second Coming Nig Ltd remains one of the largest LPG
          suppliers in Nigeria.
          <br />
          <br />
          Through our innovative approach to distributing gas to meet energy
          needs in homes and companies, we have continually ensured domestic
          cooking gas is available across the country.
          <br />
          <br />
          Availability of gas, household and kitchen wares and healthy life
          style is the hallmark of Second Coming Nigeria Limited
        </p>
      </div>

      <div
        style={{
          backgroundSize: "cover",
          paddingTop: paddingTop,
        }}
      >
        <div
          style={{
            textAlign: "center",
            backgroundImage: `url(${imageUrl2})`,
            backgroundSize: "cover",
            padding: "20px",
            minHeight: "100vh",
          }}
          id="section2"
        >
          <p
            className="textAboveBgMed"
            style={{ fontSize: headFont, marginTop: marginTop, color: "black" }}
          >
            Our Services
          </p>

          {/* <p
            style={{
              maxWidth: "800px",
              margin: "0 auto",
              padding: padding,
              fontSize: font,
              textAlign: "left",
              color: "black"

              // textJustify: "inter-character"
            }}
          > */}
          <Col style={{ padding: "20px", color: "#000" }}>
            <Row>
              <Col style={{ padding: "40px" }}>
                {" "}
                <FontAwesomeIcon
                  icon={faFilter}
                  style={{ marginRight: "10px" }}
                />
                Pre-filling Inspection
              </Col>
              <Col style={{ padding: "40px" }}>
                <FontAwesomeIcon
                  icon={faFillDrip}
                  style={{ marginRight: "10px" }}
                />
                Refilling of empty LPG cylinders
              </Col>
              <Col style={{ padding: "40px" }}>
                <FontAwesomeIcon
                  icon={faWrench}
                  style={{ marginRight: "10px" }}
                />
                Replacement & repairs of cylinders fittings
              </Col>
              <Col style={{ padding: "40px" }}>
                <FontAwesomeIcon
                  icon={faDiagnoses}
                  style={{ marginRight: "10px" }}
                />
                Detection & fixing of valves leakages
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "40px" }}>
                <FontAwesomeIcon
                  icon={faGasPump}
                  style={{ marginRight: "10px" }}
                />{" "}
                Cylinder maintenance & replacement where necessary
              </Col>
              <Col style={{ padding: "40px" }}>
                {" "}
                <FontAwesomeIcon
                  icon={faMoneyCheckAlt}
                  style={{ marginRight: "10px" }}
                />
                Sales of LPG cylinders in different sizes and accessories
              </Col>
              <Col style={{ padding: "40px" }}>
                <FontAwesomeIcon
                  icon={faUtensils}
                  style={{ marginRight: "10px" }}
                />
                Sales of Kitchen utensils and home appliances
              </Col>
              <Col style={{ padding: "40px" }}>
                <FontAwesomeIcon
                  icon={faNetworkWired}
                  style={{ marginRight: "10px" }}
                />
                Customer Advisory and Education
              </Col>
            </Row>
          </Col>
          {/* </p> */}
        </div>
      </div>

      <div
        style={{
          color: "white",
          textAlign: "center",
          backgroundColor: "black",
          padding: "20px",
        }}
        id="section3"
      >
        <p
          className="textAboveBgMed"
          style={{
            fontSize: headFont,
            color: "white",
            marginTop: marginTop,
          }}
        >
          Our Outlets
        </p>
        <div class="container">{content}</div>
        <br />
        <br />
        <br />
        <br />
      </div>

      <div
        style={{
          backgroundSize: "cover",
          paddingTop: paddingTop,
        }}
      >
        <div
          style={{
            textAlign: "center",
          }}
          id="section4"
        >
          <br />
          <br /> <br />
          <p
            className="textAboveBgMed"
            style={{ fontSize: headFont, color: "#000", marginBottom: "20px" }}
          >
            Our Gallery
          </p>
          <br />
          <br />
          <br />
          <AutoplaySlider
            play={true}
            cancelOnInteraction={false}
            interval={6000}
            bullets={false}
            cssModule="aws-btn"
          >
            {arr.map((num) => (
              <div key={num - "thrillee"} data-src={strUrl(num + 1)} />
            ))}
          </AutoplaySlider>
        </div>
      </div>
      <div
        style={{
          color: "white",
          backgroundColor: "#000",
          textAlign: "center",
          paddingTop: paddingTop,
          padding: "20px",
        }}
      >
        <br />
        <p
          className="textAboveBgMed"
          style={{ fontSize: headFont, paddingTop: "20px", color: "#fff" }}
        >
          News
        </p>
        <br />
        <br />
        <img src={require("../news1.png")} style={{ width: 600 }}></img>

        <p
          style={{
            color: "#fff",
            maxWidth: "800px",
            margin: "0 auto",
            padding: padding,
            fontSize: font,
            textAlign: "center",
            textJustify: "inter-character",
          }}
        >
          As a measure to cushion the effect of the restriction of movement in
          Abia State, in other to curtail the spread of the Corona virus, Second
          Coming Nig Ltd donated 35,000 liters of cooking gas to the State, and
          no fewer than a thousand people benefited .
        </p>
        <br />
        <br />
      </div>

      <div
        style={{
          color: "white",
          textAlign: "center",
        }}
        id="section6"
      >
        <p
          className="textAboveBgMed"
          style={{ fontSize: headFont, marginTop: marginTop, color: "black" }}
        >
          Our Location{" "}
        </p>
        <br />
        <br /> <br />
        <Map />
      </div>

      {/* <div>
        <div
          style={{
            color: "white",
            textAlign: "center",

            padding: "20px"
          }}
          id="section5"
        >
          <p className="textAboveBgMed" style={{ fontSize: headFont }}>
            Contact Us
          </p>
          <p
            style={{
              color: "#fff",
              maxWidth: "800px",
              margin: "0 auto",
              marginBottom: "40px",
              fontSize: "20px",
              paddingTop: 50,
              textJustify: "inter-character"
            }}
          >
            Plot 6 CMD Road Magodo, opposite Esporta Hotel & Suite Magodo Phase
            2 extension, Magodo, Lagos 08033482906,08075222774 <br />
            <br /> Secondcoming_gas@yahoo.com <br />
            info@2ndcomingltd.com
          </p>
        </div>
      </div> */}

      <div
        style={{
          backgroundImage: `url(${bgb})`,
          color: "#000",
          padding: "60px",
        }}
        id="section5"
      >
        <Row>
          <Col style={{ paddingRight: "100px" }}>
            <p>
              <u>Our Address</u>
              <br />
              <br />
              <strong>
                Plot 6 CMD Road Magodo, opposite Esporta Hotel & Suite Magodo
                Phase 2 extension, Magodo, Lagos 08054899632,08075222774 <br />
              </strong>
            </p>
          </Col>
          <Col>
            <u>Contact Us</u>
            <br />
            <br />
            <strong>
              Secondcoming_gas@yahoo.com <br />
              info@2ndcomingltd.com
            </strong>
          </Col>
          <Col style={{ paddingRight: "100px" }}>
            <p>
              <u>Social Media</u>
              <br />
              <br />
              <div
                style={{
                  marginBottom: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={instagram} width={15} />
                <span style={{ marginLeft: 5 }}>Secondcomingltd</span>
              </div>
              <div
                style={{
                  marginBottom: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={linkedin} width={15} />
                <span style={{ marginLeft: 5 }}>
                  Second Coming Nigeria Limited
                </span>
              </div>
              <div
                style={{
                  marginBottom: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={facebook} width={15} />
                <span style={{ marginLeft: 5 }}>
                  Second Coming Nigeria Limited
                </span>
              </div>
            </p>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingRight: "100px" }}></Col>
          <Col style={{ textAlign: "center" }}>
            <img
              src={logobelow}
              style={{ width: 100, marginBottom: 20, alignItems: "center" }}
            ></img>
            <br />
            <strong> SECOND COMING NIG. LTD.</strong>
          </Col>
          <Col style={{ paddingRight: "100px" }}></Col>
        </Row>
      </div>
    </div>
  );
}

export default Home;
