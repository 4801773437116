import React from "react";
import { Row, Col, Button } from "react-bootstrap";

import outlet from "../../src/outlet.svg";


function Outlet(props) {
 

  return (
    <Row>
      <Col>
        <div
          style={{
            marginTop: "50px",
            marginRight: "20px",
            backgroundImage: `url(${outlet})`,
            minHeight: "85vh",
            backgroundRepeat: "no-repeat",
            padding: "50px",
            width: "50vh",
            textAlign: "left"
          }}
        >
          <Col>
            <Row>
              <div
                className="textAboveBgMed"
                style={{ paddingBottom: "15px", fontSize:"20px"}}
              >
                <p>{props.address}</p>
              </div>
            </Row>
            <Row>
              <div
                className="textAboveBgLight"
                style={{ paddingBottom: "15px" }}
              >
                {props.phoneNumber}
              </div>
            </Row>
            <Row>
              <div
                className="textAboveBgMed"
                style={{
              
                  fontSize: "20px",
                 
                 
                }}
              >
                Opening Days
              </div>
            </Row>
            <Row>
              <div className="textAboveBgLight">{props.weekDays}</div>
            </Row>
            <Row>
              <div
                className="textAboveBgLight"
                style={{ paddingBottom: "20px" }}
              >
                {props.weekTimes}
              </div>
            </Row>
            <Row>
              <div className="textAboveBgLight" style={{}}>
                {props.extraDays}
              </div>
            </Row>
            <Row>
              <div
                className="textAboveBgLight"
                style={{ paddingBottom: "15px" }}
              >
                {props.extraTimes}
              </div>
            </Row>
          </Col>
        </div>
      </Col>
    </Row>
  );
}

export default Outlet;
