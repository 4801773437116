import React, { Component } from "react";
import ReactMapGL from "react-map-gl";

export default class Map extends Component {
  //  state = {
  //    viewport: {
  //      width: window.innerWidth,
  //      height: window.innerWidth / 2,
  //      latitude: 6.61666,
  //      longitude: 3.366238,
  //      zoom: 20
  //    }
  //  };

  render() {
    //  var token =
    //    "pk.eyJ1IjoiaXlpb2xhIiwiYSI6ImNrM3N5Z3RxaDBiNTczbW80cmIwZGh3anEifQ.d-B77XmYQtjmprtZ-GGlYQ";
    return (
      //  <ReactMapGL
      //    {...this.state.viewport}
      //    onViewportChange={viewport =>
      //      this.setState({ viewport })
      //    }
      //    mapboxApiAccessToken={token}
      //  />

      <iframe
        width="100%"
        height="600"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Second%20Coming%20Nigeria%20Limited,%20Plot%206%20CMD%20Road%20Magodo,%20opposite%20Esporta%20Hotel%20&amp;%20Suite%20Magodo%20Phase%202%20extension,%20Magodo%20St,%20Lagos+()&amp;t=&amp;z=21&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
    );
  }
}
